import { render, staticRenderFns } from "./PdpTables.vue?vue&type=template&id=56c57f18&lang=pug&"
import script from "./PdpTables.vue?vue&type=script&lang=js&"
export * from "./PdpTables.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Pharmacy: require('/codebuild/output/src1831708630/src/frontend/components/Pharmacy.vue').default})

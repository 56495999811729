import { render, staticRenderFns } from "./Pharmacies.vue?vue&type=template&id=39480c92&lang=pug&"
import script from "./Pharmacies.vue?vue&type=script&lang=js&"
export * from "./Pharmacies.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src1831708630/src/frontend/components/Icon.vue').default,FormPharmacies: require('/codebuild/output/src1831708630/src/frontend/components/form/Pharmacies.vue').default})


import Vue from 'vue'
import { PDFDocument, rgb } from 'pdf-lib'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
// eslint-disable-next-line import/no-named-as-default
import VueSignaturePad from 'vue-signature-pad'
import { slugify } from '~/util/string'

Vue.use(VueSignaturePad)
const WHITE = rgb(1, 1, 1)

// technically this should be a backend endpoint along with all the coordinates below
const SOA_DOC_URL = 'https://doctorschoice.s3.us-east-1.amazonaws.com/common-pdfs/SOA_UHVA25HM0257087_000.pdf'
const checkboxes = [
    { k: 'mapd', x: 64, y: 631 },
    { k: 'pdp', x: 64, y: 617 },
    { k: 'supp', x: 64, y: 604 },
    { k: 'dental', x: 365, y: 631 },
    { k: 'hospital', x: 364, y: 617 },
]
const textfields = [
    { k: 'sign_date', x: 445, y: 425, width: 120, backgroundColor: WHITE },
    // { k: 'representative_name', x: 64, y: 355 },
    // { k: 'representative_relationship', x: 285, y: 355 },
    { k: 'agent_name', x: 64, y: 290, width: 190 },
    // { k: 'agent_phone', x: 260, y: 292, width: 190, height: 25, backgroundColor: WHITE },
    // { k: 'agent_id', x: 460, y: 290, width: 100 },
    { k: 'beneficiary_name', x: 64, y: 245, width: 190 },
    { k: 'beneficiary_phone', x: 260, y: 245, width: 190, height: 25, backgroundColor: WHITE },
    { k: 'appointment_date', x: 460, y: 245, width: 100, height: 20, backgroundColor: WHITE },
    { k: 'beneficiary_address', x: 64, y: 208, width: 500 },
    { k: 'contact_method', x: 64, y: 172, width: 120, readonly: true },
    // { k: 'appointment_plans', x: 190, y: 172, width: 300 },
]
const allFields = [...checkboxes, ...textfields]
const allFieldsMap = _.keyBy(allFields, 'k')
const defaultAppearances = { backgroundColor: undefined, borderColor: undefined, borderWidth: 0 }
const defaultCheckboxAppearances = { width: 9, height: 9, ...defaultAppearances }
const defaultTextfieldAppearances = { width: 200, height: 23, ...defaultAppearances }

const cursiveFonts = [
    'Alex Brush', 'Herr Von Muellerhoff', 'Mr Dafoe',

    // 'Great Vibes', 'Allura', 'Bilbo', 'Dr Sugiyama',
    // 'Euphoria Script', 'Italianno', 'Kaushan Script',
    // 'Kristi', 'Lobster', 'Meddon', 'Mr De Haviland', 'Norican', 'Pacifico',
    // 'Parisienne', 'Pinyon Script', 'Rochester', 'Rouge Script', 'Sacramento', 'Satisfy',
    // 'Shadows Into Light', 'Snell Roundhand', 'Tangerine',
]

function isFontAvailable (font) {
    return true
    // const canvas = document.createElement('canvas')
    // const context = canvas.getContext('2d')
    // context.font = '72px monospace'
    // const defaultWidth = context.measureText('abcdefghijklmnopqrstuvwxyz').width
    // context.font = `72px ${font}, monospace`
    // const testWidth = context.measureText('abcdefghijklmnopqrstuvwxyz').width
    // return testWidth !== defaultWidth
}

export default {
    middleware: ['isLoggedIn'],
    props: {
        skipSoa: {
            type: Boolean,
            default: false
        },
        soa60days: {
            type: [String, Object],
            default: null
        }
    },
    data () {
        return {
            signed: false,
            soaDoc: null,
            soaForm: null,
            soaPdfUrl: null,
            soaPdfViewerUrl: null,
            clientSigMode: 0,
            clientSigText: '',
            clientSigTextFont: null,
            clientSigTextColor: '#000000',
            clientSigCoordinates: null,
            clientSigModal: false,
            clientSigImage: null,
            recentAppointment: null,
            recentAppointmentDate: null,
        }
    },
    computed: {
        ...mapGetters([
            'year',
            'client',
            'agent',
        ]),
        availableCursiveFonts () {
            return cursiveFonts.filter(isFontAvailable)
        },
        availableColors () {
            return [
                '#000000',
                '#0000FF',
                '#FF0000'
            ]
        },
        isRecentAppointmentMeeting () {
            return !!this.recentAppointment?.context?.acuity_appointment_id
        },
        isRecentAppointmentDateInThePast () {
            return this.recentAppointmentDate && moment().diff(this.recentAppointmentDate, 'days') > 0
        },
        soaFilename () {
            return `SOA_${slugify(this.client.c_complete_name)}_signed_${moment().format('YYYY-MM-DD')}.pdf`
        },
        readonly () {
            return this.signed || this.soa60days || this.skipSoa
        },
    },
    async mounted () {
        this.$store.dispatch('busy')
        const { client, canAgent } = this.$store.getters
        const results = await this.$api.getRecentAppointments({ client_id: canAgent ? client.id : null, size: 1 })
        const appointments = results?.items || []

        this.recentAppointment = appointments[0]
        this.recentAppointmentDate = appointments[0]?.context?.data?.time ? moment(appointments[0]?.context?.data?.time) : null
        if (this.soa60days) {
            await this.loadSoaViewer(this.soa60days)
        } else {
            await this.loadNewSoaForm()
        }
        this.$store.dispatch('idle')
        this.setGlobal('$soa', this)
        this.clientSigTextFont = this.availableCursiveFonts[0]
        this.clientSigText = this.client ? this.client?.first_name + ' ' + this.client?.last_name : ''
    },
    methods: {
        async uploadSignedSoa () {
            const pdfBytes = await this.soaDoc.save()
            const blob = new Blob([pdfBytes], { type: 'application/pdf' })
            const file = new File([blob], this.soaFilename)
            const form = new FormData()
            form.append('file', file)
            const resp = await this.$try('uploadSignedSoa', form, { ...this.formFields })
            if (!resp.error) {
                this.$alert({ message: 'SOA form uploaded successfully', variant: 'success' })

                this.signed = true
                // if we emit soa60daysUpdate here, this is "removing" the /soa route from the surveyRoutes
                // so mutating an array while we're in it, which is messing up the navigation,
                // we don't neccesarilly need to update the soa60days here, because the /soa route is already shown
                // if the user refreshes the pages, the soa60days will be updated from the backend
                // setting signed=true should be just find the hide the "edit" and "submit" buttons
                // this.$emit('soa60daysUpdate', resp)
            }
        },
        fillSoaForm () {
            const form = this.soaForm
            const now = moment().format('MM/DD/YYYY')
            // const agentPhone = this.agent?.phone ? this.agent?.phone.replace(/[^0-9]/g, '') : ''
            const beneficiaryPhone = this.client?.phone ? this.client?.phone.replace(/[^0-9]/g, '') : ''
            const appointmentDate = this.recentAppointmentDate ? this.recentAppointmentDate.format('MM/DD/YYYY') : ''
            this.formFields = {
                sign_date: now,
                // representative_name: '',
                // representative_relationship: '',
                agent_name: `${this.agent?.first_name} ${this.agent?.last_name}`,
                // agent_phone: agentPhone,
                // agent_id: '',
                beneficiary_name: `${this.client?.c_complete_name}`,
                beneficiary_phone: beneficiaryPhone,
                appointment_date: appointmentDate,
                beneficiary_address: this.client?.c_address,
                contact_method: 'Virtual',
                // appointment_plans: '',
            }
            Object.entries(this.formFields).forEach(([key, soa60days]) => {
                const field = form.getTextField(key)
                field.setText(`${soa60days}`)
                if (allFieldsMap[key]?.readonly) {
                    field.enableReadOnly()
                }
            })
        },
        async loadNewSoaForm () {
            await this.createSoaForm()
            await this.fillSoaForm()
            await this.loadSoaViewer()
        },
        updateClientSigTrackerCoordinates () {
            if (this.soa60days || !this.$refs.soaPdfFrame) {
                return
            }
            const document = this.$refs.soaPdfFrame.contentWindow.document
            const container = document.querySelector('#viewerContainer')
            const page = document.querySelector('.page')
            if (!page || !container) {
                return
            }
            const scale = this.getPdfViewerScale()
            const top = (482 * scale - container.scrollTop)
            const left = (1 + page.getBoundingClientRect().x)

            if (top >= 0) {
                this.clientSigCoordinates = {
                    top,
                    left
                }
            } else {
                this.clientSigCoordinates = null
            }
        },
        async createSoaForm () {
            if (!this.blankSoaBytes) {
                this.blankSoaBytes = await fetch(SOA_DOC_URL).then(res => res.arrayBuffer())
            }

            this.soaDoc = await PDFDocument.load(this.blankSoaBytes)
            const pdfDoc = this.soaDoc

            const page = pdfDoc.getPages()[0]

            // const textColor = rgb(0, 0, 0)
            const form = pdfDoc.getForm()

            checkboxes.forEach((f) => {
                const checkbox = form.createCheckBox(`${f.k}`)
                checkbox.addToPage(page, { ...defaultCheckboxAppearances, ...f })
            })

            textfields.forEach((f) => {
                const field = form.createTextField(`${f.k}`)
                field.addToPage(page, { ...defaultTextfieldAppearances, ...f })
            })
            this.soaForm = form
        },
        textToImage (text) {
            const scaleFactor = 4
            const canvas = document.createElement('canvas')
            canvas.width = 300 * scaleFactor
            canvas.height = 50 * scaleFactor
            const ctx = canvas.getContext('2d')
            ctx.scale(scaleFactor, scaleFactor)
            ctx.font = `20px ${this.clientSigTextFont}`
            ctx.fillStyle = this.clientSigTextColor
            ctx.fillText(text, 10, 30)
            return canvas.toDataURL('image/png')
        },
        clientInsertSig () {
            const onpad = this.clientSigMode && this.$refs?.clientSignaturePad
            if (onpad) {
                return this.clientSignSoaForm(this.$refs.clientSignaturePad.saveSignature())
            } else {
                const image = this.textToImage(this.clientSigText)
                this.sentryCaptureMessage(`Client Insert Text Signature=${this.clientSigText}`, { extra: { text: this.clientSigText } })
                return this.clientSignSoaForm({ data: image })
            }
        },
        async clientSignSoaForm ({ isEmpty, data }) {
            if (isEmpty) {
                return
            }
            this.$store.dispatch('busy')
            await this.loadNewSoaForm()

            const pdfDoc = this.soaDoc
            const page = pdfDoc.getPages()[0]
            const sigData = data
            this.clientSigImage = await pdfDoc.embedPng(sigData)
            page.drawImage(this.clientSigImage, {
                x: 64,
                y: 410,
                width: 300,
                height: 50,
            })
            this.clientSigModal = false
            this.updateClientSigTrackerCoordinates()
            this.$nextTick(async () => {
                await this.loadSoaViewer()
                this.$store.dispatch('idle')
            })
        },
        whenPdfViewerEventBusReady (pdfViewer) {
            if (pdfViewer.eventBus) {
                pdfViewer.eventBus.on('scalechanging', _.debounce(this.updateClientSigTrackerCoordinates, 5))
            } else {
                setTimeout(() => this.whenPdfViewerEventBusReady(pdfViewer), 100)
            }
        },
        async loadSoaViewer (url) {
            this.$store.dispatch('busy')
            if (url) {
                this.soaPdfViewerUrl = url
            } else {
                const pdfBytes = await this.soaDoc.save()
                const blob = new Blob([pdfBytes], { type: 'application/pdf' })
                this.soaPdfUrl = URL.createObjectURL(blob)
                this.soaPdfViewerUrl = (this.$config?.publicPath || '/') + 'pdfjs/web/viewer.html?file=' + this.soaPdfUrl
                this.$nextTick(() => {
                    // Add load event listener to iframe
                    const iframe = this.$refs?.soaPdfFrame
                    if (!iframe) {
                        this.$store.dispatch('idle')
                        return
                    }
                    const iframeWindow = iframe.contentWindow
                    window.addEventListener('webviewerloaded', () => {
                        try {
                            this.updateClientSigTrackerCoordinates()
                            const pdfViewer = this.getPdfViewer()
                            this.whenPdfViewerEventBusReady(pdfViewer)
                            this.setGlobal('$pdfViewer', pdfViewer)
                            // pdfViewer.setLayoutMode(pdfViewer.LayoutMode.Single)
                            const viewerContainer = iframeWindow.document.querySelector('#viewerContainer')
                            viewerContainer.addEventListener('scroll', _.debounce(this.updateClientSigTrackerCoordinates, 5))
                            this.$store.dispatch('idle')
                            iframeWindow.addEventListener('resize', _.debounce(this.updateClientSigTrackerCoordinates, 5))
                        } catch (e) {
                            console.error('Cannot access iframe contentWindow:', e)
                        }
                    })
                })
            }
        },
        getPdfViewer () {
            return this.$refs?.soaPdfFrame?.contentWindow?.PDFViewerApplication
        },
        getPdfViewerScale () {
            return this.getPdfViewer()?.toolbar?.pageScale
        },
    }
}

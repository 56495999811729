
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import GuidanceMixin from '~/mixins/guidance'

export default {
    mixins: [GuidanceMixin],
    props: {
        id: {
            type: [String, Number],
            default: null
        },
        editable: {
            type: Boolean,
            default: false
        },
        magicUrl: {
            type: String,
            default: '/'
        },
        showLeftOverPlans: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            watched: false,
            filterGroup: null,
            cards_aep: [
                {
                    text: 'AEP Intro',
                    value: 'aep_intro'
                },
                {
                    text: 'AEP SUPP',
                    value: 'aep_supp',
                    config: {
                        component: 'CardConfigSwitchOrStay',
                        props: {
                            type: 'supp'
                        },
                        value: {},
                    },
                    autoCondition: () => this.summarySuppPlans.length,
                },
                {
                    text: 'AEP PDP',
                    value: 'aep_pdp',
                    config: {
                        sync: 'aep_pdp_tables',
                        component: 'CardConfigSwitchOrStay',
                        props: {
                            type: 'pdp'
                        },
                        value: {},
                    },
                    autoCondition: () => this.summaryPdpPlans.length,
                },
                {
                    text: 'AEP PDP Tables',
                    value: 'aep_pdp_tables',
                    config: {
                        sync: 'aep_pdp',
                        component: 'CardConfigSwitchOrStay',
                        props: {
                            type: 'pdp'
                        },
                        value: {},
                    },
                    autoCondition: () => this.summaryPdpPlans.length,
                },
                {
                    text: 'AEP MAPD',
                    value: 'aep_mapd',
                    config: {
                        sync: 'aep_mapd_tables',
                        component: 'CardConfigSwitchOrStay',
                        props: {
                            type: 'mapd'
                        },
                        value: {},
                    },
                    autoCondition: () => this.summaryMapdPlans.length,
                },
                {
                    text: 'AEP MAPD Tables',
                    value: 'aep_mapd_tables',
                    config: {
                        sync: 'aep_mapd',
                        component: 'CardConfigSwitchOrStay',
                        props: {
                            type: 'mapd'
                        },
                        value: {},
                    },
                    autoCondition: () => this.summaryMapdPlans.length,
                },
                {
                    text: 'AEP Outro',
                    value: 'aep_outro',
                    autoCondition: true,
                },
            ],
            cards_intro: [
                {
                    text: 'Cover',
                    value: 'cover',
                    autoCondition: false,
                    uncheckable: true,
                    uneditable: true,
                    untitled: true
                },
                { text: 'Intro', value: 'intro', autoCondition: true, },
                { text: 'Next Steps Summary', value: 'next_steps_summary', autoCondition: () => true },
            ],
            cards_gm: [
                { text: 'Part A and B Overview', value: 'part_a_and_b_overview', autoCondition: () => this.asNotAEPConfig, },
                { text: 'Income Surcharges', value: 'income_surcharges', autoCondition: () => this.high_income && this.asNotAEPConfig },
                { text: 'Medicare Coverage Gaps', value: 'coverage_gaps', autoCondition: () => this.asNotAEPConfig },
            ],
            cards_combos: [],
            cards_compare: [
                { text: 'Plans Compare', value: 'group_plans_compare', autoCondition: () => false, },
            ],
            cards_next_steps: [
                { text: 'Next Steps Intro', value: 'step_intro', autoCondition: () => this.asNotAEPConfig || (this.asAEPConfig /* && this.asSwitchConfig */), uncheckable: true },
                { text: 'Sign up for Medicare Parts A and B', value: 'step_part_a_and_b_signup', autoCondition: () => this.isIEP && this.asNotAEPConfig, },
                { text: 'Sign up for Medicare Part A', value: 'step_part_a_signup', autoCondition: () => this.dobx.gte65 && this.isSEPLEC && this.asNotAEPConfig, },
                { text: 'Sign up for Medicare Part B', value: 'step_part_b_signup', autoCondition: () => this.dobx.gte65 && this.isSEPLEC && this.asNotAEPConfig, },
                { text: 'Adjust Income Surcharge', value: 'step_ssa44_file', autoCondition: () => this.projected_income_decrease && (this.isIEP || (this.dobx.gte65 && this.isSEPLEC)) && this.asNotAEPConfig, },
                { text: 'Recommendation/Enroll in additional coverage', value: 'step_enroll_plan', autoCondition: () => this.asNotAEPConfig || (this.asAEPConfig /* && this.asSwitchConfig */) },
                {
                    text: 'Plans Actions Enroll',
                    value: 'step_actions_enroll_plan',
                    config: {
                        component: 'CardConfigExcludePlans',
                        value: { excluded: [] },
                    },
                    autoCondition: () => this.asNotAEPConfig || (this.asAEPConfig /* && this.asSwitchConfig */),
                    uncheckable: true,
                    uneditable: true,
                    untitled: true
                },
                {
                    text: 'Plans Actions Keep',
                    value: 'step_actions_keep_plans',
                    autoCondition: () => this.asAEPConfig,
                    uncheckable: true,
                    uneditable: true,
                    untitled: true
                },
                { text: 'When you decide to retire', value: 'step_when_retiring', autoCondition: () => (this.isSEPLEC && this.asNotAEPConfig), },
                { text: 'Things to watch out for after enrolling in Medicare', value: 'step_post_enrollment_watch', autoCondition: () => !(this.working_p65 && this.lt65) && this.isSEPLEC && this.asNotAEPConfig, },
                { text: 'ICHRA Reimbursement Overview', value: 'step_ichra', autoCondition: () => this.ichra && this.asNotAEPConfig, },
            ],
            cards_outro: [
                { text: 'Recap', value: 'recap', autoCondition: true },
                {
                    text: 'Attachments',
                    value: 'attachments',
                    autoCondition: true,
                    config: {
                        component: 'CardConfigExcludeAttachments',
                        value: { excluded: [] },
                    },
                    uncheckable: true,
                    uneditable: true,
                    // untitled: true
                },
                { text: 'Disclaimer', value: 'disclaimer', autoCondition: true },
            ],
            types: [
                { text: 'Full cards', value: 'FULL' },
                { text: 'AEP cards', value: 'AEP' },
            ],
            deletingFile: null,
            deleteFileModal: false
        }
    },
    async fetch () {
        if (!this.$store.getters.isServer) {
            await this.$store.dispatch('getSummary', { id: this.id })
        }

        const summary = this.$store.getters.summary
        if (!summary) {
            return this.$alert('Can\'t find summary, either specifiy an id or have it set on the store')
        }
        if (!this.$store.getters.summaryManual) {
            this.updateAutoCards()
        }
        const uid = this.$store.getters.uid
        if (!this.$store.getters.isServer) {
            await this.getSurvey({ id: uid })
        }

        const promises = []
        const survey = this.$store.getters.survey

        if ((!survey.dob || !survey.gender || !survey.zip_code) && this.header && this.header.presentQuickEditModal) {
            return this.header.presentQuickEditModal()
        }

        if (!this.$store.getters.isServer) {
            promises.push(this.$store.dispatch('loadSummaryScriptsCards'))
        }

        if (this.$store.getters.canAgent) {
            promises.push(this.$store.dispatch('loadSummaryUserGroupPlans'))
        }

        if (!this.$store.getters.isServer) {
            promises.push(this.$store.dispatch('getQuotes', {
                id: uid,
                version: parseInt(this.$route.query.version) || 2
            }))
            promises.push(this.$store.dispatch('getSummaryFiles', { id: summary.id }))
            // i don't want to hold everything for this... nextTick it
            this.$nextTick(() => this.getOpportunities(uid))
        }

        try {
            await Promise.all(promises)
        } catch (e) {}

        if (this.editable) {
            this.filterGroup = this.$store.getters.groupId

            const aep = !!this.isAEP && !this.isIEP
            if (!_.isBoolean(this.$store.getters.summaryAep)) {
                this.$store.commit('summaryAep', aep)
            }
            if (survey?.updated !== this.$store.getters.summarySurveyUpdated && Object.keys(summary?.data?.plans || {}).length) {
                this.$store.commit('summarySurveyUpdated', survey.updated)
            }
            if (!this.watched) {
                this.watched = true

                this.$watch('summary.data.config.aep', (newAep, oldAep) => {
                    if (newAep !== oldAep) {
                        this.updateAutoCards()
                    }
                })
                this.$watch('summary.data.config.mode', (newMode, oldMode) => {
                    if (newMode !== oldMode) {
                        this.updateAutoCards()
                    }
                })
                this.$watch('summaryCombosOptions', function (newCombosOptions) {
                    const cmap = _.keyBy(newCombosOptions, 'value')
                    const removeCards = []
                    this.summarySwitchedOnCardsCombos.forEach((card) => {
                        if (!cmap[card]) {
                            removeCards.push(card)
                        }
                    })
                    if (removeCards.length) {
                        console.info('removing cards', removeCards)
                    }
                    removeCards.forEach((card) => {
                        this.onCardRemove(card, { auto: true })
                    })
                })
                this.$watch('summaryDataStringified', _.debounce((newSummaryDataStringified, oldSummaryDataStringified) => {
                    if (newSummaryDataStringified !== oldSummaryDataStringified) {
                        return this.onSummaryUpdate()
                    }
                }, 300))
            }
        }
    },
    computed: {
        ...mapGetters([
            'summary',
            'summaryPlans',
            'summaryFiles',
            'summaryDataCards',
            'summaryScriptsCards',
            'summaryScriptsCardsMap',
            'summarySwitchedOnCardsList',
            'summarySwitchedOnCardsMap',
            'summarySwitchedOnCardsCombos',
            'getSummaryComboPlanIdsByCard',
            'getSummaryComboPlansByCard',
            'summaryCombosOptions',
            'aepOpportunities',
            'survey',
            'client',
            'isServer',
            'isAEP',
            'isIEP',
            'paying',
            'ichra',
            'crmBase',
        ]),
        summaryDataStringified () {
            // https://github.com/vuejs/vue/issues/2164#issuecomment-542766308
            return JSON.stringify(this.summary?.data || {})
        },
        summaryCards: {
            get () {
                return this.$store.getters.summaryCards
            },
            set (cards) {
                this.$store.commit('summaryCards', cards)
                this.$store.commit('summaryManual', true)
            }
        },
        summaryAep: {
            get () {
                return this.$store.getters.summaryAep
            },
            set (aep) {
                this.$store.commit('summaryAep', aep)
            }
        },
        summaryMode: {
            get () {
                return this.$store.getters.summaryMode
            },
            set (mode) {
                this.$store.commit('summaryMode', mode)
                this.$store.commit('summaryManual', true)
            }
        },
        isAEPCardFilter () {
            return this.summary?.data?.config?.type === 'AEP'
        },
        asAEPConfig () {
            return !!this.summary?.data?.config?.aep
        },
        asNotAEPConfig () {
            return !this.summary?.data?.config?.aep
        },
        modeConfig () {
            return this.summary?.data?.config?.mode
        },
        asSwitchConfig () {
            return this.modeConfig && this.modeConfig.includes('SWITCH')
        },
        autoCards () {
            return this.cardsList.filter((card) => {
                if (!this.isAEPCardFilter && card.aep) {
                    return false
                }
                return _.isFunction(card.autoCondition) ? card.autoCondition() : _.isBoolean(card.autoCondition) ? card.autoCondition : true
            })
        },
        cardsList () {
            return _.flatten(Object.keys(this.$data)
                .filter((k) => {
                    if (k === 'cards_combos') {
                        this.cards_combos = this.summaryCombosOptions
                    }
                    return /cards_/.test(k)
                })
                .map(k => this.$data[k]))
                .map((card, i) => {
                    card.index = i
                    card.aep = /^aep_/.test(card.value)
                    return card
                })
        },
        cardsMap () {
            return _.keyBy(this.cardsList, 'value')
        },
    },
    watch: {
        year () {
            return this.$fetch()
        },
    },
    mounted () {
        this.setGlobal('$summary', this)
    },
    methods: {
        ...mapActions([
            'getSurvey',
            'getOpportunities',
            'updateSurvey',
        ]),
        refetch () {
            this.$fetch()
        },
        onSummaryUpdate () {
            this.$api.updateSummary({
                id: this.id,
                data: { ..._.cloneDeep(this.$store.state.summary.data) }
            })
        },
        async summaryApproveToggleOpportunities (opps) {
            opps = [].concat(opps)
            const approved = opps.every(opp => opp.Summary_Approved__c)
            const { error } = await this.$try('updateCrmOpportunities', {
                opportunities: opps, data: { Summary_Approved__c: !approved }
            })
            if (error) {
                return this.$alert(error)
            }
            const uid = this.$store.getters.uid
            await this.getOpportunities(uid)
        },
        async reset () {
            // description: 'This will reset all manual changes done by you or anyone else',
            const value = await this.$bvModal.msgBoxConfirm('Are you sure you want to reset the summary to its original version?', {
                title: 'Please Confirm',
                okVariant: 'danger',
                okTitle: 'Yes, I am sure',
                cancelTitle: 'No',
                footerClass: 'p-2',
                centered: true
            })
            if (value) {
                await this.$store.dispatch('resetSummary', {
                    id: this.summary.id,
                    data: _.cloneDeep(this.$store.state.defaultSummaryData)
                })
                this.updateAutoCards()
            }
        },
        updateAutoCards () {
            const cards = this.autoCards.map(c => c.value).slice(0)
            this.$store.commit('summaryCards', cards)
        },
        email () {
            this.$bvModal.show(`send-summary-${this.summary.id}`)
        },
        approve () {},
        disapprove () {},
        onCardRemove (card, options = {}) {
            this.onCardSwitch({ value: card, ...options })
        },
        onHtmlSave (card, html) {
            this.$store.commit('summaryDataCard', { card, ...this.summaryDataCards[card], html })
            this.$store.commit('summaryManual', true)
        },
        onHtmlReset (card) {
            this.$store.commit('summaryDataCard', { card, ...this.summaryDataCards[card], html: null })
            this.$store.commit('summaryManual', true)
        },
        onCheck (card) {
            this.$store.commit('summaryDataCard', { card, ...this.summaryDataCards[card], checked: true })
            this.$store.commit('summaryManual', true)
        },
        onUncheck (card) {
            this.$store.commit('summaryDataCard', { card, ...this.summaryDataCards[card], checked: false })
            this.$store.commit('summaryManual', true)
        },
        onConfigValue (card, value, stop) {
            let dataCard = _.cloneDeep(this.summaryDataCards[card])
            if (!this.summaryDataCards[card]) {
                dataCard = {}
            }
            if (!dataCard.config) {
                dataCard.config = _.cloneDeep(this.cardsMap[card]?.config || {})
            }
            dataCard.config.value = value
            this.$store.commit('summaryDataCard', { card, ...this.summaryDataCards[card], ...dataCard })
            this.$store.commit('summaryManual', true)
            if (dataCard.config.sync && !stop) {
                return this.onConfigValue(dataCard.config.sync, value, true)
            }
        },
        getCardIndex (value) {
            return this.cardsMap[value]?.index
        },
        onCardSwitch (cardOption) {
            const delayed = cardOption?.delayed
            const auto = cardOption?.auto

            const fn = () => {
                const cards = _.cloneDeep(this.$store.getters.summaryCards)

                if (this.summarySwitchedOnCardsMap[cardOption?.value]) {
                    const idx = _.findIndex(cards, card => card === cardOption?.value)
                    if (idx > -1) {
                        cards.splice(idx, 1)
                    }
                } else {
                    let ibi
                    const coi = this.getCardIndex(cardOption.value)
                    cards.some((card, idx) => {
                        const ci = this.getCardIndex(card)
                        if (coi < ci) {
                            ibi = idx
                            return true
                        }
                        return false
                    })
                    if (!_.isNil(ibi)) {
                        cards.splice(ibi, 0, cardOption.value)
                    } else if (cardOption?.value) {
                        cards.push(cardOption?.value)
                    }
                }
                this.$store.commit('summaryCards', cards)
                if (!auto) {
                    this.$store.commit('summaryManual', true)
                }
            }
            if (delayed) {
                setTimeout(fn, 300)
            }
            return fn()
        },
        searchPlans ({ query }) {
            const queryRe = new RegExp(query || '', 'i')
            const allowedTypesFilter = (/* p */) => {
                return true
                // return (this.hasNotMapd && p.mapd) ||
                //     (this.hasNotSupp && p.supp) ||
                //     (p.pdp && (this.hasNotPdp || (this.summaryAep && this.hasNotMin2Ppds)))
            }
            const plans = this.$store.getters.plans.filter((p) => {
                if (
                    allowedTypesFilter(p) &&
                    (!query || queryRe.test(p.name) || queryRe.test(p.carrier))
                ) {
                    return true
                }
                return false
            })
            return { items: plans }
        },
        onFileUploaded (file) {
            this.$store.commit('addSummaryFile', file)
            this.$store.commit('summaryManual', true)
        },
        presentDeleteFile (file) {
            this.deletingFile = file
            this.deleteFileModal = true
        },
        async deleteFile (file) {
            const id = file?.file_id || file?.id
            if (!id) {
                return
            }
            const { error } = await this.$try('deleteFile', id)
            if (!error) {
                this.$store.commit('removeSummaryFile', file)
                this.$store.commit('summaryManual', true)
            }
        },
        async downloadPdf (format = 'pdf') {
            const ext = /pdf/i.test(format) ? 'pdf' : format
            const type = /pdf/i.test(format) ? 'application/pdf' : `text/${format}`
            const res = await this.$try('generateBase64', {
                template: 'pdf-summary',
                client_id: this.client.id,
                user_id: this.user.id,
                summary_id: this.summary.id,
                props: { magic_redirect: this.magicUrl },
                format
            })
            const fileName = `${process.env.NODE_ENV === 'development' ? res.tool + '_' : ''}ConsultationSummary_${_.upperFirst(_.camelCase(this.client.first_name + '_' + this.client.last_name))}_${this.$moment().format('MM-DD-YYYY')}.${ext}`
            this.downloadBase64(fileName, type, res.base64)
        },
    }
}

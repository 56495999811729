import { render, staticRenderFns } from "./PlanSummary.vue?vue&type=template&id=8b33ab60&lang=pug&"
import script from "./PlanSummary.vue?vue&type=script&lang=js&"
export * from "./PlanSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src1831708630/src/frontend/components/Icon.vue').default,PlanComparing: require('/codebuild/output/src1831708630/src/frontend/components/PlanComparing.vue').default,PlanCompare: require('/codebuild/output/src1831708630/src/frontend/components/PlanCompare.vue').default,EnrollForm: require('/codebuild/output/src1831708630/src/frontend/components/EnrollForm.vue').default})

import { render, staticRenderFns } from "./PlansTable.vue?vue&type=template&id=77151230&scoped=true&lang=pug&"
import script from "./PlansTable.vue?vue&type=script&lang=js&"
export * from "./PlansTable.vue?vue&type=script&lang=js&"
import style0 from "./PlansTable.vue?vue&type=style&index=0&id=77151230&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77151230",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src1831708630/src/frontend/components/Icon.vue').default,PlansTableLi: require('/codebuild/output/src1831708630/src/frontend/components/PlansTableLi.vue').default,Tiers: require('/codebuild/output/src1831708630/src/frontend/components/Tiers.vue').default,Address: require('/codebuild/output/src1831708630/src/frontend/components/Address.vue').default,Pharmacy: require('/codebuild/output/src1831708630/src/frontend/components/Pharmacy.vue').default,DrugsMonthlyByPharmacy: require('/codebuild/output/src1831708630/src/frontend/components/DrugsMonthlyByPharmacy.vue').default,DrugsMonthlyByDrug: require('/codebuild/output/src1831708630/src/frontend/components/DrugsMonthlyByDrug.vue').default,DrugsYearlyByPharmacy: require('/codebuild/output/src1831708630/src/frontend/components/DrugsYearlyByPharmacy.vue').default,DrugsInfo: require('/codebuild/output/src1831708630/src/frontend/components/DrugsInfo.vue').default,PlanProvidersList: require('/codebuild/output/src1831708630/src/frontend/components/PlanProvidersList.vue').default,Stars: require('/codebuild/output/src1831708630/src/frontend/components/Stars.vue').default})

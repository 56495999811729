
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        controlsOnly: {
            type: Boolean,
            default: false,
        },
        benefitsOnly: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false
        },
        magicUrl: {
            type: String,
            default: '/'
        },
        showEnroll: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            planModal: false,
            groupPlanModal: false,
            enrollModal: false,
            userGroupPlanModal: false,
            adding: false,
            removing: false,
            uploadingFilesModal: false,
            uploadingFiles: null,
            editingFile: null,
            editFileModal: false,
            editingExtras: null,
            editingExtrasModal: false,
            deletingFile: null,
            deleteFileModal: false,
            filters: null,
            isDoubleCheckPriceLoading: false,
        }
    },
    computed: {
        ...mapGetters([
            'year',
            'isDonutHoleYear',
            'surveyRoutesMap',
            'survey',
            'client',
            'norxSituations',
            'hasNorxSituations',
            'planFilesByPlanIdInState',
            'planFilesByPlanIdOutState',
            'isUserGroupPlanInSummary',
            'isPlanInOpportunities',
            'planDrugDeductibleApplicableTiers',
            'providers',
            'uid'
        ]),
        notCoveredDrugs () {
            return Object.values(this.plan?.drug_info || {}).filter((drug) => {
                return drug.tier === 0 || drug.is_not_covered
            }).map(d => `${d.package?.drug?.name} (tier ${d.tier})${d.coverage_reason ? `(${_.lowerCase(d.coverage_reason)})` : ''}`)
        },
        isPreviewOnly () {
            return this.$store.getters.quotesVersion !== 2
        },
        isNotPreviewOnly () {
            return !this.isPreviewOnly
        },
        comparing: {
            get () {
                return !!this.$store.state.comparing[this.plan.id]
            },
            set (v) {
                return v ? this.$store.commit('addComparing', this.plan) : this.$store.commit('removeComparing', this.plan)
            }
        },
        drugDeductibleApplicableTiers0: {
            get () {
                const v = this.planDrugDeductibleApplicableTiers(this.plan)
                if (!v?.length) {
                    return null
                } else {
                    return v[0]
                }
            },
            set (v) {
                if (this.planDrugDeductibleApplicableTiers(this.plan) === v) {
                    return v
                }
                let tiers = null
                if (v === null) {
                    tiers = []
                } else {
                    // create list from v+ to 5
                    tiers = Array.from({ length: 6 }, (v, k) => k).slice(v)
                }
                return this.$store.dispatch('updatePlanDrugDeductibleApplicableTiers', { plan: this.plan, tiers })
            }
        },
        cardPlanFilesInState () {
            return this.planFilesByPlanIdInState[this.plan?.id] || []
        },
        cardPlanFilesOutState () {
            return this.planFilesByPlanIdOutState[this.plan?.id] || []
        },
        isGroupPlan () {
            return this.plan.plan_type === 'gp'
        },
        inSummary () {
            return this.isGroupPlan ? this.$store.getters.isUserGroupPlanInSummary(this.plan) : this.$store.getters.isPlanInSummary(this.plan)
        },
        inOpportunities () {
            return this.$store.getters.isPlanInOpportunities(this.plan)
        },
        otherCosts () {
            return [
                this.plan.mapd || this.plan.ma ? { text: `${_.isNumber(this.plan.deductible_cost_share) ? this.money(this.plan.deductible_cost_share) + ' medical deductible' : this.plan.deductible_cost_share}`, value: this.plan.deductible_cost_share } : null,
                this.plan.mapd || this.plan.pdp ? { text: `${_.isNumber(this.plan.drug_deductible_cost_share) ? this.money(this.plan.drug_deductible_cost_share) : this.plan.drug_deductible_cost_share} drug deductible`, value: this.plan.drug_deductible } : null,
                { text: `${this.plan.maximum_oopc || this.plan.maximum_oopc_cost_share_note} Maximum out of pocket`, value: this.plan.maximum_oopc || this.plan.maximum_oopc_cost_share_note },
                { text: `${this.money(this.plan.emergency_copay_cost_share)} Emergency copay`, value: this.plan.emergency_copay_cost_share },
                { text: `${this.money(this.plan.pcp_copay_cost_share)} Primary care copay`, value: this.plan.pcp_copay_cost_share },
                { text: `${this.money(this.plan.specialist_copay_cost_share)} Specialist copay`, value: this.plan.specialist_copay_cost_share },
                // todo: add this to augmented plans in items instead of hardcoded value?
                /aarp/i.test(this.plan.carrier) && this.plan.plan_type === 'supp' ? { text: '$20/year AARP Membership is required', value: '$12' } : null
            ].filter(cost => cost && !_.isNil(cost.value) && cost.value !== '')
        },
        isOriginalFileIgnored: {
            cache: false,
            get () {
                return this.$store.getters.isPlanOriginalFileIgnored(this.plan)
            }
        },
        moreInfo () {
            const result = { Dental: {}, Vision: {}, Hospital: {} }
            for (const [key, value] of Object.entries(this.plan.benefits || {})) {
                const [category, name] = key.split('__')

                if (/DENTAL/i.test(category)) {
                    if (!result.Dental[value.cost_share]) result.Dental[value.cost_share] = []
                    result.Dental[value.cost_share].push(name.toLowerCase().replaceAll('_', ' '))
                }

                if (/VISION/i.test(category)) {
                    if (!result.Vision[value.cost_share]) result.Vision[value.cost_share] = []
                    result.Vision[value.cost_share].push(name.toLowerCase().replaceAll('_', ' '))
                }

                if (key === 'OUTPATIENT_HOSPITAL') {
                    const k = key.toLowerCase().replaceAll('_', ' ')
                    if (!result.Hospital[k]) result.Hospital[k] = []
                    result.Hospital[k].push(value.cost_share.replaceAll(/<br \/>/g, ' '))
                }

                if (key === 'INPATIENT_HOSPITAL') {
                    const k = key.toLowerCase().replaceAll('_', ' ')
                    if (!result.Hospital[k]) result.Hospital[k] = []
                    result.Hospital[k].push(value.cost_share.replaceAll(/<br \/>/g, ' '))
                }
            }

            const res = Object.entries(result).reduce((acc, [key, value]) => {
                if (Object.keys(value).length > 0) {
                    acc[key] = value
                }
                return acc
            }, {})

            return res
        }
    },
    methods: {
        doubleCheckPrice () {
            this.$nextTick(async () => {
                this.isDoubleCheckPriceLoading = true
                try {
                    const body = { year: this.year, carrier: this.plan.carrier, plan_name: this.plan.name, user: this.uid }
                    const data = await this.$try('doubleCheckPrice', body, { spinner: false })
                    if (typeof data === 'number') {
                        this.$store.commit('updateQuotePrice', { id: this.plan.id, price: data })
                    }
                } finally {
                    this.isDoubleCheckPriceLoading = false
                }
            })
        },
        async addPlanToSummary () {
            this.adding = true
            if (this.isGroupPlan) {
                await this.$store.dispatch('addSummaryUserGroupPlan', this.plan)
            } else {
                await this.$store.dispatch('addSummaryPlan', this.plan)
            }
            this.adding = false
            this.$emit(this.isGroupPlan ? 'addSummaryUserGroupPlan' : 'addSummaryPlan', this.plan)
        },
        async removePlanFromSummary () {
            this.removing = true
            if (this.isGroupPlan) {
                await this.$store.dispatch('removeSummaryUserGroupPlan', this.plan)
            } else {
                await this.$store.dispatch('removeSummaryPlan', this.plan)
            }
            this.removing = false
            this.$emit(this.isGroupPlan ? 'removeSummaryUserGroupPlan' : 'removeSummaryPlan', this.plan)
        },
        presentUploadingFiles () {
            this.uploadingFilesModal = true
        },
        onFileUploaded (file) {
            this.$nextTick(() => {
                this.$store.commit('addPlanFile', file)
                this.$store.commit('addGroupPlanFile', { plan: this.plan, file })
            })
        },
        onFilesUploaded (newFiles) {
            this.uploadingFilesModal = false
        },
        ignorePlanOrignalFile () {
            return this.$store.dispatch('ignorePlanOrignalFile', { plan: this.plan })
        },
        unignorePlanOrignalFile () {
            return this.$store.dispatch('unignorePlanOrignalFile', { plan: this.plan })
        },
        presentEditFile (file) {
            this.editingFile = file
            this.editFileModal = true
        },
        presentDeleteFile (file) {
            this.deletingFile = file
            this.deleteFileModal = true
        },
        onFileEdited (file) {
            this.$store.commit('editPlanFile', file)
            this.editingFile = null
            this.editFileModal = false
        },
        async deleteFile (file) {
            const id = file?.file_id || file?.id
            if (!id) {
                return
            }
            const { error } = await this.$try('deleteFile', id)
            if (!error) {
                this.$store.commit('removePlanFile', file)
                this.$store.commit('removeGroupPlanFile', { plan: this.plan, file })
            }
        }
    }
}

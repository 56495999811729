import url from 'url'
import _ from 'lodash'
import { parseFullName } from 'parse-full-name'

export const resolveType = function (token) {
    if (typeof token !== 'string') {
        return token
    }
    if (token.length < 15 && token.match(/^-?\d+(\.\d+)?$/)) {
        token = parseFloat(token)
    } else if (token.match(/^true|false$/i)) {
        token = Boolean(token.match(/true/i))
    } else if (token === 'undefined') {
        token = undefined
    } else if (token === 'null') {
        token = null
    } else if (token.trim && /^(\{|\])/.test(token.trim())) {
        try {
            token = JSON.parse(token)
        } catch (e) {
            console.warn('resolveType(token)', token, e)
        }
    }
    return token
}

export const lengthInUtf8Bytes = (str) => {
    const m = encodeURIComponent(str).match(/%[89ABab]/g)
    return str.length + (m ? m.length : 0)
}

export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp
    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
    return bytes.toFixed(dp) + ' ' + units[u]
}

export const stringToColor = (str = '') => {
    // inspired from https://stackoverflow.com/a/16348977/493756
    /* eslint-disable no-bitwise */
    let hash = 0;
    [...str].forEach((char, i) => {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    })
    let color = '#'
    for (const i of Array(3).keys()) {
        const value = (hash >> (i * 8)) & 0xFF
        color += (`00${value.toString(16)}`).substr(-2)
    }
    return color
}

export const hashCode = function (str = '') {
    // https://stackoverflow.com/a/34842797/493756
    return str.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash) + currVal.charCodeAt(0), 0)
}

export const hashObject = function (obj) {
    return hashCode(JSON.stringify(obj))
}

export const arrayJoinWithOperator = (arr, operator = 'and') => {
    if (!arr || !arr.length) {
        return ''
    }
    if (arr.length === 1) {
        return arr[0]
    }
    const array = arr.slice(0)
    const last = array.pop()
    return `${array.join(', ')} ${operator} ${last}`
}

export const urlToLocation = (link) => {
    let a
    if (!link) {
        return a
    }
    if (typeof document === 'undefined') {
        try {
            a = new url.URL(link)
        } catch (e) {
        // eslint-disable-next-line
        a = url.parse(link)
        }
    } else {
        a = document.createElement('a')
        a.href = link
    }
    // now this has a host, hostname, pathname, just like like window.location
    return a
}

export const locationToUrl = (a) => {
    return `${a.protocol}//${a.host}${a.pathname}${a.search || ''}${a.hash || ''}`
}

export const slugify = str => str.toLowerCase()
    .replace(/\//g, '')
    .replace(/ /g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^\w-]+/g, '')

export const titlelize = (str = '') => {
    return _.startCase(str || '').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export const toUpperCaseList = arr => arr.map(s => s.toUpperCase())

export const toUpperList = arr => arr.map(_.toUpper)

export const titlelizeList = arr => arr.map(titlelize)

// php version
// https://gist.github.com/guedressel/0daa170c0fde65ce5551#gistcomment-2809571
export const mimeToFA = (mimeType) => {
    // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
    const mimeToFAMap = {
        // Images
        image: 'fa-file-image',
        // Audio
        audio: 'fa-file-audio',
        // Video
        video: 'fa-file-video',
        // Documents
        'application/pdf': 'fa-file-pdf',
        'application/msword': 'fa-file-word',
        'application/vnd.ms-word': 'fa-file-word',
        'application/vnd.oasis.opendocument.text': 'fa-file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fa-file-word',
        'application/vnd.ms-excel': 'fa-file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fa-file-excel',
        'application/vnd.oasis.opendocument.spreadsheet': 'fa-file-excel',
        'application/vnd.ms-powerpoint': 'fa-file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml': 'ffa-file-powerpoint',
        'application/vnd.oasis.opendocument.presentation': 'fa-file-powerpoint',
        'text/plain': 'fa-file-alt',
        'text/html': 'fa-file-code',
        'application/json': 'fa-file-code',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fa-file-powerpoint',
        // Archives
        'application/gzip': 'fa-file-archive',
        'application/zip': 'fa-file-archive',
        'application/x-zip-compressed': 'fa-file-archive',
        // Misc
        'application/octet-stream': 'fa-file-archive',
    }
    if (mimeToFAMap[mimeType]) {
        return mimeToFAMap[mimeType]
    }

    return _.find(mimeToFAMap, (fa, mime) => mimeType.includes(mime)) || 'fa-file'
}

export const money = (number, options = {}) => {
    if (typeof number === 'string') {
        number = parseFloat(number.replace(/[^0-9.-]+/g, ''))
    }
    const nb = parseFloat(number)
    // if no decimals, don't show .00
    if (nb % 1 === 0) {
        options.minimumFractionDigits = options.minimumFractionDigits || 0
    }
    const localStringOptions = { ...{ minimumFractionDigits: 2, maximumFractionDigits: 2 }, ...options }
    return (_.isNil(nb) || isNaN(nb) ? '' : `${options.percent ? '' : '$'}${`${nb.toLocaleString(undefined, localStringOptions)}`.replace(/^\$/, '')}${options.percent ? '%' : ''}`)
}

export const pluralize = (num, singular, plural, showNum = true) => {
    if (!plural) plural = `${singular}s`
    return `${showNum ? `${num} ` : ''}${num === 1 ? singular : plural}`
}

export const summarizeList = (list, emptyMessage = '', maxShow = 3, operator = 'and') => {
    // If there's nothing in the list, we're done
    if (!list || list.length === 0) return emptyMessage

    // If there's only one item, we're done
    if (list.length === 1) return list[0]

    let commaJoined = []
    let andJoined = ''
    if (list.length > maxShow) {
        commaJoined = list.slice(0, maxShow - 1)
        andJoined = pluralize(list.length - maxShow + 1, 'other')
    } else {
        commaJoined = list.slice(0, list.length - 1)
        andJoined = list[list.length - 1]
    }

    return `${commaJoined.join(', ')}, ${operator} ${andJoined}`
}

export const yesno = (bool, fallback) => {
    if (_.isNil(bool) && !_.isNil(fallback)) {
        return fallback
    }
    if (!_.isBoolean(bool)) {
        return bool
    }
    return bool ? 'Yes' : 'No'
}

export const address = (model) => {
    if (model.location) {
        return address(model.location)
    }
    return `${model.address ? `${model.address}, ` : ''}${model.street_1 ? `${model.street_1}, ` : ''}${model.street ? `${model.street}, ` : ''}${model.street_2 ? `${model.street_2}, ` : ''}${model.unit ? `${model.unit}, ` : ''}${model.city ? `${model.city}, ` : ''}${model.state ? `${model.state} ` : ''}${model.zip_code || ''}`
}

export const name = (model, { initials, skipPreferred, skipFull } = {}) => {
    /* eslint-disable */
    let firstInitial = false
    let middleInitial = false
    let lastInitial = false
    if (initials) {
        if (initials === true) {
            firstInitial = true
            middleInitial = true
            lastInitial = true
        } else {
            firstInitial = !!initials?.first
            middleInitial = !!initials?.middle
            lastInitial = !!initials?.last
        }
    }
    if (!model) {
        return ''
    }
    let n
    let preferred_name
    if (model.preferred_name && !skipPreferred) {
        // eslint-disable-next-line prefer-destructuring
        preferred_name = model.preferred_name
    }
    if (model.first_name || model.last_name) {
        let fullName = ''
        fullName += `${model.salutation ? `${firstInitial ? '' : `${model.salutation} `}` : ''}`
        fullName += `${model.first_name ? `${firstInitial ? `${model.first_name[0]}.` : model.first_name}` : ''}`
        fullName += `${model.middle_name ? middleInitial ? ` ${model.middle_name[0]}.` : ` ${model.middle_name}` : ''}`
        fullName += `${model.last_name ? lastInitial ? ` ${model.last_name[0]}.` : ` ${model.last_name}` : ''}`
        fullName += `${model.suffix ? `${lastInitial ? '' : ` ${model.suffix}`}` : ''}`
        if (!preferred_name && model.name) {
            preferred_name = model.name
        }
        n = fullName
    } else if (model.name) {
        n = model.name
    } else if (model.username) {
        n = model.username
    } else if (model.email) {
        n = model.email
    } else if (typeof model === 'string') {
        n = model
    } else {
        n = model.toString()
    }
    if (preferred_name && n !== preferred_name) {
        return `${titlelize(preferred_name)}${skipFull ? '' : ` (${titlelize(n)})`}`
    }
    return n
}

export const fromName = (fullName, options = {}) => {
    return parseFullName(fullName, 'all', 1, 0, 1)
}

const statSuffixes = ['K', 'M', 'B', 'T', 'Q']
export const stat = (number, currency) => {
    let suffixIdx = -1
    while (number >= 1000) {
        number /= 1000
        suffixIdx += 1
    }
    const doubleDigits = currency && suffixIdx === -1
    const roundingAmount = doubleDigits ? 100 : 10
    number = Math.round(number * roundingAmount) / roundingAmount
    if (doubleDigits) number = number.toFixed(2)
    return `${number}${suffixIdx > -1 ? statSuffixes[suffixIdx] : ''}`
}

export const toGender = (val) => {
    if (!val) {
        return null
    }
    if (val[0].toUpperCase() === 'M') {
        return 'M'
    }
    if (val[0].toUpperCase() === 'F') {
        return 'F'
    }
    return null
}

export const toBool = (val) => {
    // null or undefined
    if (val == null) {
        // todo: see if we can just set intermediate=false on all checkboxes
        return false
    }
    if (typeof val === 'boolean') {
        return val
    }
    val = val.toUpperCase()    
    if (val === 'TRUE' || 'YES' || 'Y' || '1' || 'ON' || 'T' || 'X' || 'CHECKED' || 'SELECTED' || 'ENABLED' || 'ACTIVE' || 'VALID' || 'OK' || 'GOOD' || 'POSITIVE' || 'POS') {
        return true
    }
    if (val === 'FALSE' || 'NO' || 'N' || '0' || 'OFF' || 'F' || 'EMPTY' || 'BLANK' || 'NULL' || 'VOID' || 'INVALID' || 'BAD' || 'NEGATIVE' || 'NEG') {
        return false
    }
}

export const toAlpha = (num) => {
    if(num < 1 || num > 26 || typeof num !== 'number'){
       return -1;
    }
    const leveller = 64;
    return String.fromCharCode(num + leveller);
};

export const toQuerystring = (obj = {}, sep = null) => {
    let qs = new URLSearchParams(obj).toString()
    if (sep) {
        qs = qs.replace(/&/g, sep)
    }
    return qs
}
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'survey',
            'preferredFirstName',
            'year',
            'paying',
            'paying_employee_or_spouse',
            'cms564Assist',
            'ichra',
            'irmaaB',
            'irmaaD',
            'isAEP',
            'isIEP',
            'isSEP',
            'isSEPLEC',
            'isSEPMoving',
            'income',
            'summaryPlansMap',
            'summaryPlansList',
            'summaryUserGroupPlansList',
            'hasPartA',
            'hasPartB',
            'hasMedicare',
            'projectedIncomeDecrease',
            'projectedIncomeIncrease',
            'projectedIncomeChange',
            'getProjectedIrmaaB',
            'getProjectedIrmaaD',
            'partAPremium',
            'partADeductible',
            'partAPremiumLt30',
            'partAPremiumGte30Lte39',
            'partAPremiumGt40',
            'partBPremium',
            'partBDeductible',
            'partDGapEntrance',
            'partDGapBrandDrugPercent',
            'partDGapGenericDrugPercent',
            'partDCatastrophicEntrance',
            'partDCatastrophicCoveredDrugPercent',
            'partDCatastrophicGenericDrugCopay',
            'partDCatastrophicBrandDrugCopay',
            'partBDatePast6m',
            'providersMap',
            'pharmaciesMap',
            'packagesMap',
            'dobx',
            'isProviderInNetwork',
            'isProviderOutNetwork',
            'carriersProvidersSearchLinks',
            'summaryAep',
            'summaryMode',
            'summaryMapdPlans',
            'summarySuppPlans',
            'summaryPdpPlans',
            'summaryCardsMap',
            'hasMapd',
            'hasSupp',
            'hasPdp',
            'hasAllPlanTypes',
            'missingTypes',
            'allowedTypes',
            'hasNotMapd',
            'hasNotSupp',
            'hasNotPdp',
            'hasNotMin2Ppds',
            'summarySuppPdpPlans',
            'summaryCombos',
            'summaryCombosMap',
            'summaryCombosOrderedAsCards',
            'summarySwitchedOnCardsList',
            'summaryCombosOptions',
            'summaryRecommendedPlans',
            'lastDonutHoleYear',
            'isDonutHoleYear',
        ]),
        has_special_issues () {
            return this.esrd || this.als || this.hcs
        },
        situation () {
            return this.survey?.situation
        },
        ss_retirement_benefits_from_spouse () {
            return this.survey?.ss_retirement_benefits_from_spouse
        },
        disabled () {
            return this.survey?.disabled
        },
        married () {
            return this.survey?.married
        },
        has_esrd () {
            return this.survey?.esrd
        },
        has_als () {
            return this.survey?.als
        },
        has_hcs () {
            return this.survey?.hcs
        },
        on_employer_plan () {
            return this.survey?.pre_medicare_coverage === 'EMPLOYER'
        },
        employer_coverage () {
            return this.on_employer_plan
        },
        collecting_ssi () {
            return this.survey?.collecting_ssi
        },
        collecting_disability () {
            return this.collecting_ssi
        },
        retired () {
            return this.survey?.retired
        },
        retiring () {
            return this.survey?.retiring
        },
        hsa () {
            return this.survey?.hsa
        },
        tricare () {
            return this.survey?.tricare
        },
        champva () {
            return this.survey?.champva
        },
        tricare_or_champva () {
            return this.tricare || this.champva
        },
        va () {
            return this.survey?.va
        },
        fep_blue () {
            return this.survey?.fep_blue
        },
        medicaid () {
            return this.survey?.medicaid
        },
        has_part_a () {
            return this.hasPartA
        },
        has_part_b () {
            return this.hasPartB
        },
        has_medicare () {
            return this.hasMedicare
        },
        age () {
            return this.dobx?.age
        },
        eq64 () {
            return this.dobx?.eq64
        },
        gte65 () {
            return this.dobx?.gte65
        },
        lt65 () {
            return this.dobx?.lt65
        },
        t65 () {
            return this.dobx?.t65
        },
        turning_65 () {
            return this.t65
        },
        preferred_first_name () {
            return this.preferredFirstName
        },
        first_name () {
            return this.survey?.first_name
        },
        last_name () {
            return this.survey?.last_name
        },
        part_a_premium () {
            return this.partAPremium
        },
        part_a_deductible () {
            return this.partADeductible
        },
        part_a_premium_lt30 () {
            return this.partAPremiumLt30
        },
        part_a_premium_gte30_lte39 () {
            return this.partAPremiumGte30Lte39
        },
        part_a_premium_gt40 () {
            return this.partAPremiumGt40
        },
        part_b_premium () {
            return this.partBPremium
        },
        part_b_deductible () {
            return this.partBDeductible
        },
        part_b_irmaa () {
            return this.irmaaB
        },
        part_d_irmaa () {
            return this.irmaaD
        },
        projected_part_b_irmaa () {
            return this.getProjectedIrmaaB()
        },
        projected_part_d_irmaa () {
            return this.getProjectedIrmaaD()
        },
        part_d_date_past6m () {
            return this.partBDatePast6m
        },
        premium_covered () {
            return this.survey?.tax_quarters_paid === 'QUARTERS_40'
        },
        premium_not_covered_gte_30_lte_39 () {
            return this.survey?.tax_quarters_paid === 'QUARTERS_30_39'
        },
        premium_not_covered_lt_30 () {
            return this.survey?.tax_quarters_paid === 'QUARTERS_LT_30'
        },
        premium_unsure () {
            return !this.survey?.tax_quarters_paid
        },
        high_income () {
            return this.part_a_irmaa
        },
        projected_income_decrease () {
            return this.projectedIncomeDecrease
        },
        projected_income_increase () {
            return this.projectedIncomeIncrease
        },
        projected_income_change () {
            return this.projectedIncomeChange
        },
        during_aep () {
            return this.isAEP
        },
        moving () {
            return this.survey?.moving
        },
        multiple_states () {
            return this.survey?.multiple_states
        },
        dental_health () {
            return this.survey?.dental_health
        },
        working_p65 () {
            return this.survey?.working_p65
        },
        effective_date () {
            return this.survey?.effective_date
        },
        sep () {
            return this.isSEP
        },
        sep_lec () {
            return this.isSEPLEC
        },
        sep_moving () {
            return this.isSEPMoving
        },
        iep () {
            return this.isIEP
        },
    },
    methods: {
        presentQuestion () {}
    }
}
